// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/LandingPage.css */
.landing-page {
    padding: 50px;
}

.cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/LandingPage.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,eAAe;AACnB","sourcesContent":["/* src/components/LandingPage.css */\n.landing-page {\n    padding: 50px;\n}\n\n.cards-container {\n    display: flex;\n    justify-content: center;\n    gap: 20px;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
