// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/LanguageSwitcher.css */
.language-switcher {
    position: fixed;
    top: 20px;
    right: 20px;
}

.language-switcher button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #ffb300;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #000;
    font-weight: bold;
}

.language-switcher button.active {
    background-color: #e0a800;
}

.language-switcher button:hover {
    background-color: #e0a800;
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageSwitcher.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;IACI,eAAe;IACf,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* src/components/LanguageSwitcher.css */\n.language-switcher {\n    position: fixed;\n    top: 20px;\n    right: 20px;\n}\n\n.language-switcher button {\n    margin: 0 5px;\n    padding: 5px 10px;\n    background-color: #ffb300;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    color: #000;\n    font-weight: bold;\n}\n\n.language-switcher button.active {\n    background-color: #e0a800;\n}\n\n.language-switcher button:hover {\n    background-color: #e0a800;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
