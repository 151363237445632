// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/PayPalCard.css */
.paypal-card {
    background-color: #1e1e1e;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 20px;
    width: 250px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.paypal-card h2 {
    margin-bottom: 10px;
}

.paypal-card p {
    margin: 5px 0;
}

.paypal-card p strong {
    margin-right: 5px;
}

.paypal-card button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #ffb300;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #000;
    font-weight: bold;
}

.paypal-card button:disabled {
    background-color: #555;
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/PayPalCard.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":["/* src/components/PayPalCard.css */\n.paypal-card {\n    background-color: #1e1e1e;\n    border: 1px solid #333;\n    border-radius: 8px;\n    padding: 20px;\n    width: 250px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);\n}\n\n.paypal-card h2 {\n    margin-bottom: 10px;\n}\n\n.paypal-card p {\n    margin: 5px 0;\n}\n\n.paypal-card p strong {\n    margin-right: 5px;\n}\n\n.paypal-card button {\n    margin-top: 15px;\n    padding: 10px 20px;\n    background-color: #ffb300;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    color: #000;\n    font-weight: bold;\n}\n\n.paypal-card button:disabled {\n    background-color: #555;\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
